#hambergar:hover {
  background-color: white !important;
}
.fa {
  color: #fff;
  background-color: #000;
  border-radius: 50%;
  padding: 5px;
  font-size: 18px !important;
}
.darkcss {
  background-color: #eee !important;
}
.w3-button {
  /* background-color:blueviolet; */
  color: white;
  /* position:fixed */
  margin: auto;
  right: 0;
  top: 20px;
  /* right:/20px; */
  padding: 0.25rem;
  transition: transform 0.3s ease-in-out;
  /* background: linear-gradient(to right, #ff416c, #ff4b2b); */
}
.w3-button.active {
  transform: translateX(-100px);
}

.w3-sidebar {
  width: 70%;
  max-width: 300px;
  height: 100%;
  background-color: #fff;
  color: black !important;
  position: fixed;
  top: 0;
  left: -500px;
  height: 100vh;
  transition: all 0.5s;
  z-index: 99999999 !important;
}
.w3-container {
  padding: 0px;
  top: 0 !important;
  margin: auto;
  background: #fff !important;
}
.w3-teal {
  background: white !important;
}
.w3-bar-block .w3-bar-item {
  background-color: #fff;
  font-size: 15px;
  /* padding: 20px; */
  height: 74px;
  position: relative;
  width: 100%;
  align-items: center;
  display: flex;
  color: #000 !important;
  border-bottom: 0.5px lightgrey solid;
}
.w3-bar-item div {
  font-size: 16px;
  font-weight: bold;
}
/*.w3-bar-block .w3-bar-item:hover{*/
/*    background-image: linear-gradient(rgb(61, 128, 197), rgb(37, 61, 118));*/
/*    color: black !important;*/
/*}*/

.arrow {
  position: absolute;
  right: 25px;
}
.arrow > img {
  height: 11px;
  width: 6px;
  max-width: 100%;
}

.icon {
  overflow: hidden;
  position: relative;
}
.icon > img {
  height: 28px;
  width: 28px;
  /* max-width: 100%; */
}
#sidebarOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999999;
  display: none;
  transition: all 0.5s ease-in-out 0s;
}
