.headerContainer {
    background-color: white; 
    max-width: 480px;
    width: 100%;
    position: fixed;
    height: 50px;
    padding: 0 10px;
    z-index: 2;
    align-items: center;
    display: flex;
    padding-top: 0px;
    padding-right: 10px;
    padding-bottom: 0px;
    padding-left: 10px;
    box-shadow:0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
}

.sideNavIcon {
    overflow: hidden;
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
}

.menu_items {
    position: absolute;
    right: 10px;
    align-items: center;
    display: flex;
}

.sideNavIcon>img {
    height: 14px;
    width: 16.5px;
    margin-bottom: 14px;
  
}

.navLogo>img {
    height: 3.0rem;
    width: 3.0rem;
}

.menu_items>.box {
    align-items: center;
    display: flex;
}

.menu_items>.box {
    background-color: #f8f8f8;
    border: 1px solid #ededed;
    border-radius: 3px;
    height: 30px;
    position: relative;
    text-decoration: none;
    min-width: 90px;
    
}

.moneyIcon_container {
    overflow: hidden;
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
}

.moneyBox_add {
    background-color: #ededed;
    bottom: 0;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 17px;
   

}
.referBox_add {
   
    bottom: 0px;
    position: relative;
    right: 0px;
    top: 0px;
    /*height: 95%;*/
    width: 25px;
    border-radius: 50%;
    border :2px solid #f4bc41;
    color: #f4bc41;
    margin-left: 5px;

    box-shadow: rgb(244 188 65) 0px 0px 0.2rem;
   text-align: center;

}

.moneyIcon_container>img {
    width: 20px;
    margin-left: 4px;
}

.moneyBox_header {
    color: #959595;
    font-size: .6em;
    font-weight: 500;
    text-transform: uppercase;
}

.moneyBox_text {
    color: #2c2c2c;
    font-size: .8em;
    font-weight: 900;
    position: relative;
    bottom: 4px;
}
.login_btn {
    color: blue;
    font-size: 1em;
    font-weight: 900;
    position: relative;
    bottom: 0px;
    /* padding-left: 151px; */
}

.moneyBox_add>img {
    height: 9px;
    width: 9px;
    margin-left: 6px;
    
}

.buttoncolor{
    background-image: linear-gradient(rgb(61, 128, 197), rgb(37, 61, 118)) !important;
    color: black !important;
    border: 2px  solid #f4bc41 !important;
    border-radius: 14px;
    
}
